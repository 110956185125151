import { Injectable } from '@angular/core';
import { BaseService } from '../classes/base.service';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class RefCodeService extends BaseService {

  constructor(private httpservice: HttpService) {
    super();
  }

  lookupmap: any = {};

  getMappedKey(key: string) {
    return this.lookupmap[key] ? this.lookupmap[key] : key;
  }

  getRefCodes(types: string[]): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let refcodes: any = {};
      let lookupdata = { "keys": types.map(x => this.getMappedKey(x)) };
      this.httpservice.Post<any>(false, false, '/lookup', lookupdata).then(
        (resp) => {
          types.forEach(x => {
            refcodes[x] = resp.data[this.getMappedKey(x)];
            if (!refcodes[x]) {
              refcodes[x] = [];
            }
          }
          );

          resolve(refcodes);
        }
      );
    });
  }
}
